import React from "react"

import { Link } from "gatsby"

import Layout from "../components/layout"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import SEO from "../components/seo"

import Pagination from "../components/pagination"
import { graphql } from "gatsby"

const PostPreview = ({ post }) => {
  const url = `/blog/posts/${post.slug}/`
  return (
    <Link
      to={url}
      key={post.id}
      className="flex flex-col rounded-lg shadow-lg cursor-pointer overflow-hidden"
    >
      <img
        className="flex w-full h-64 object-cover"
        src={post.feature_image}
        alt=""
      />
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="text-sm font-medium text-highlight-600">
            {post.primary_tag.name}
          </p>
          <a href={post.href} className="block mt-2">
            <p className="text-xl h-12 font-semibold text-gray-900">
              {post.title}
            </p>
            <p className="mt-4 text-base text-gray-500">
              {post.excerpt?.substring(0, 100)}...
            </p>
          </a>
        </div>
        <div className="mt-6 flex items-center">
          <div className="flex flex-row ">
            <img
              className="h-10 w-10 rounded-full"
              src={post.primary_author?.profile_image}
              alt=""
            />
            <div className="flex flex-col ml-4">
              <span className="font-bold text-xs text-gray-900 tracking-tight">
                {post.primary_author?.name}
              </span>
              <span className="text-xs text-gray-500">{post.published_at}</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

const Blog = ({ data, location, pageContext }) => {
  const posts = data.allGhostPost.edges
  return (
    <Layout>
      <SEO
        title="TimeKeeper Blog"
        description="Practical tips to help manage time and attendance in your business."
      />
      <Navbar />
      <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              TimeKeeper Blog
            </h2>
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
              Practical tips to help manage time and attendance in your
              business.
            </p>
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {posts.map(post => (
              <PostPreview post={post.node} />
            ))}
          </div>
          <Pagination pageContext={pageContext} />
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!) {
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { primary_tag: { name: { ne: "Customers" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          slug
          title
          feature_image
          excerpt
          primary_author {
            name
            profile_image
          }
          primary_tag {
            name
          }
          published_at(formatString: "dddd, MMMM Do YYYY")
        }
      }
    }
  }
`
